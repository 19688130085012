import styled from 'styled-components';

import { Tablet } from '@/styles/layout';

const ServiceCardSection = () => {
  return (
    <Section>
      <Title data-aos={'fade-up'}>{'닥터나우,\n이렇게 활용해보세요'}</Title>
      <ServiceCardContainer>
        <Row>
          <ServiceCard
            data-aos={'fade-up'}
            href={'https://doctornow.co.kr/content/qna/realtime'}
          >
            <ServiceCardContent>
              <ServiceCardTextBox>
                <ServiceDescription>
                  {'5분 만에 받아보는\n의료 답변'}
                </ServiceDescription>
                <ServiceTitle>실시간 의료 상담</ServiceTitle>
              </ServiceCardTextBox>
              <Button>바로 가기</Button>
            </ServiceCardContent>
            <Icon
              src={'https://file.doctornow.co.kr/landing/chat-bubble-icon.png'}
            />
          </ServiceCard>
          <ServiceCard
            data-aos={'fade-up'}
            href={'https://doctornow.co.kr/content/magazine'}
          >
            <ServiceCardContent>
              <ServiceCardTextBox>
                <ServiceDescription>
                  {'에디터가 엄선한\n건강 가이드'}
                </ServiceDescription>
                <ServiceTitle>건강 매거진</ServiceTitle>
              </ServiceCardTextBox>
              <Button>바로 가기</Button>
            </ServiceCardContent>
            <Icon
              src={'https://file.doctornow.co.kr/landing/magazine-icon.png'}
            />
          </ServiceCard>
        </Row>
        <Row>
          <ServiceCard
            data-aos={'fade-up'}
            href={'https://doctornow.co.kr/hospitals'}
          >
            <ServiceCardContent>
              <ServiceCardTextBox>
                <ServiceDescription>
                  {'내가 원하는 병원 찾고\n예약까지 한 번에'}
                </ServiceDescription>
                <ServiceTitle>방문 예약</ServiceTitle>
              </ServiceCardTextBox>
              <Button>바로 가기</Button>
            </ServiceCardContent>
            <Icon
              src={
                'https://file.doctornow.co.kr/landing/hospital-sign-icon.png'
              }
            />
          </ServiceCard>
          <ServiceCard
            data-aos={'fade-up'}
            href={
              'https://doctornow.co.kr/non-benefit?treatmentMedicineUnitId=73&regionCode=11'
            }
          >
            <ServiceCardContent>
              <ServiceCardTextBox>
                <ServiceDescription>
                  {'내 근처 비급여 성지가\n궁금하다면?'}
                </ServiceDescription>
                <ServiceTitle>우리동네 진료 가격</ServiceTitle>
              </ServiceCardTextBox>
              <Button>바로 가기</Button>
            </ServiceCardContent>
            <Icon
              src={'https://file.doctornow.co.kr/landing/receipt-icon.png'}
            />
          </ServiceCard>
        </Row>
      </ServiceCardContainer>
    </Section>
  );
};

export default ServiceCardSection;

const Section = styled.div`
  display: flex;
  padding: 100px 0 120px 0;
  flex-direction: column;
  align-items: center;
  gap: 68px;
  background: ${({ theme }) => theme.color.gray20};

  @media screen and (max-width: ${Tablet}) {
    gap: 40px;
    padding: 80px 0;
  }
`;

const Title = styled.h3`
  white-space: pre-wrap;
  text-align: center;
  ${({ theme }) => `
      ${theme.typography.UI48B};
      color: ${theme.color.black};
      
      @media screen and (max-width: ${Tablet}) {
        ${theme.typography.UI32B};
      }
    `}
`;

const ServiceCardContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1080px;
  padding: 0 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  word-break: keep-all;

  @media screen and (max-width: ${Tablet}) {
    gap: 20px;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  padding: 0;
  justify-content: space-between;
  gap: 32px;
  align-self: stretch;

  @media screen and (max-width: ${Tablet}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
`;

const ServiceCard = styled.a`
  display: flex;
  padding: 32px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;

  flex: 1;
  border-radius: 12px;
  background: white;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.04);

  @media screen and (max-width: ${Tablet}) {
    width: 100%;
    flex-direction: column;
    gap: 15px;
  }
`;

const Icon = styled.img`
  width: 64px;
  height: 64px;
  align-self: center;

  @media screen and (max-width: ${Tablet}) {
    align-self: flex-end;
  }
`;
const ServiceCardContent = styled.div`
  display: flex;
  gap: 24px;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  white-space: pre-wrap;
`;

const ServiceCardTextBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;
const ServiceDescription = styled.div`
  ${({ theme }) => `
        ${theme.typography.UI20M};
        color: ${theme.color.gray500};
    `}
`;
const ServiceTitle = styled.h4`
  ${({ theme }) => `
        ${theme.typography.UI28B};
        color: ${theme.color.gray900};
    `}
`;
const Button = styled.button`
  display: flex;
  height: 48px;
  padding: 0 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: flex-start;
  border-radius: 8px;
  ${({ theme }) => `
        ${theme.typography.UI16SB};
        background:${theme.color.primary100};
        color: ${theme.color.primary700};
    `}
`;
