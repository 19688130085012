import React from 'react';
import styled, { keyframes } from 'styled-components';

import { EllipsisTextStyle } from '@/components/atom/text/DefaultText';
import { zIndex } from '@/constants/zIndex';
import { Tablet } from '@/styles/layout';
import Airbridge from '@/utils/airbridge';

const MobileAppDownloadFooter = () => {
  return (
    <Wrapper
      id={'floating-download'}
      onClick={() =>
        Airbridge.setDeepLink({
          buttonID: 'floating-download',
          deeplinkUrl: 'doctornow://',
          ctaParams: {
            cta_param_1: 'homepage',
            cta_param_2: 'ownedchannel',
            cta_param_3: 'sticky_bottom',
          },
        })
      }
    >
      <Content>
        <Text>앱 다운로드</Text>
      </Content>
    </Wrapper>
  );
};

export default MobileAppDownloadFooter;

const BottomUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Wrapper = styled.button`
  display: none;

  @media screen and (max-width: ${Tablet}) {
    cursor: pointer;
    display: flex;
    width: 100%;
    animation: ${BottomUp} 1s ease-in-out forwards;
    z-index: ${zIndex.BottomSticky};
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: ${({ theme }) => theme.color.gray900};
  width: 100%;
`;

const Text = styled.p`
  ${({ theme }) => theme.typography.UI17SB};
  color: ${({ theme }) => theme.color.white};
  ${EllipsisTextStyle};
  -webkit-line-clamp: 1;
`;
