const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="26"
    viewBox="0 0 120 26"
    fill="none"
  >
    <g clipPath="url(#clip0_166_2361)">
      <mask
        id="mask0_166_2361"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="120"
        height="26"
      >
        <path d="M120 0.253174H0V25.7467H120V0.253174Z" fill="white" />
      </mask>
      <g mask="url(#mask0_166_2361)">
        <path
          d="M49.6556 3.63954C49.6556 2.62268 48.8401 1.79834 47.8339 1.79834C46.8278 1.79834 46.0122 2.62268 46.0122 3.63954V12.8456C46.0122 13.8624 46.8278 14.6868 47.8339 14.6868C48.8401 14.6868 49.6556 13.8624 49.6556 12.8456V9.72967H50.7766C51.7052 9.72967 52.4581 8.96875 52.4581 8.03011C52.4581 7.09145 51.7052 6.33053 50.7766 6.33053H49.6556V3.63954Z"
          fill="white"
        />
        <path
          d="M41.318 2.36475C42.2079 2.36475 42.9294 3.09396 42.9294 3.9935C42.9294 4.89306 42.2079 5.62227 41.318 5.62227H34.802V10.5793H42.9995C43.8895 10.5793 44.611 11.3086 44.611 12.2081C44.611 13.1076 43.8895 13.8369 42.9995 13.8369H32.8402C31.9115 13.8369 31.1587 13.0759 31.1587 12.1373V4.06432C31.1587 3.12567 31.9115 2.36475 32.8402 2.36475H41.318Z"
          fill="white"
        />
        <path
          d="M32.8403 17.0943C32.8403 16.2339 33.5306 15.5364 34.3817 15.5364H47.9743C48.9029 15.5364 49.6557 16.2973 49.6557 17.236V22.618C49.6557 23.6349 48.8403 24.4591 47.8341 24.4591C46.828 24.4591 46.0124 23.6349 46.0124 22.618V18.6523H34.3817C33.5306 18.6523 32.8403 17.9547 32.8403 17.0943Z"
          fill="white"
        />
        <path
          d="M66.1906 4.2767C66.1906 3.37716 65.4692 2.64795 64.5791 2.64795H56.1014C55.1728 2.64795 54.4199 3.40887 54.4199 4.34753V19.2187C54.4199 20.1574 55.1728 20.9183 56.1014 20.9183H65.9805C66.8705 20.9183 67.592 20.1891 67.592 19.2896C67.592 18.39 66.8705 17.6609 65.9805 17.6609H58.0633V13.2703H63.1779C64.068 13.2703 64.7894 12.5411 64.7894 11.6415C64.7894 10.742 64.068 10.0128 63.1779 10.0128H58.0633V5.90545H64.5791C65.4692 5.90545 66.1906 5.17624 66.1906 4.2767Z"
          fill="white"
        />
        <path
          d="M71.5158 1.93994C72.5218 1.93994 73.3373 2.76428 73.3373 3.78113V22.6181C73.3373 23.635 72.5218 24.4592 71.5158 24.4592C70.5096 24.4592 69.6941 23.635 69.6941 22.6181V13.1288H67.452C66.5234 13.1288 65.7705 12.3679 65.7705 11.4292C65.7705 10.4906 66.5234 9.72963 67.452 9.72963H69.6941V3.78113C69.6941 2.76428 70.5096 1.93994 71.5158 1.93994Z"
          fill="white"
        />
        <path
          d="M80.2039 4.48916C80.2039 3.47228 79.3883 2.64795 78.3822 2.64795C77.3762 2.64795 76.5605 3.47228 76.5605 4.48916V18.9356C76.5605 19.8742 77.3134 20.635 78.2422 20.635H88.1211C89.0111 20.635 89.7326 19.9059 89.7326 19.0063C89.7326 18.1067 89.0111 17.3776 88.1211 17.3776H80.2039V4.48916Z"
          fill="white"
        />
        <path
          d="M92.9553 1.79834C93.9615 1.79834 94.777 2.62268 94.777 3.63954V9.58805H95.9682C96.9355 9.58805 97.7197 10.3807 97.7197 11.3584C97.7197 12.3362 96.9355 13.1288 95.9682 13.1288H94.777V22.6181C94.777 23.635 93.9615 24.4593 92.9553 24.4593C91.9493 24.4593 91.1338 23.635 91.1338 22.6181V3.63954C91.1338 2.62268 91.9493 1.79834 92.9553 1.79834Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M105.147 2.08154C102.361 2.08154 100.102 4.3643 100.102 7.18024V7.74677C100.102 10.5627 102.361 12.8455 105.147 12.8455H113.414C116.2 12.8455 118.459 10.5627 118.459 7.74677V7.18024C118.459 4.3643 116.2 2.08154 113.414 2.08154H105.147ZM112.573 5.19742H105.987C104.904 5.19742 103.745 6.08517 103.745 7.18024V7.74677C103.745 8.84185 104.904 9.7296 105.987 9.7296H112.573C113.657 9.7296 114.816 8.84185 114.816 7.74677V7.18024C114.816 6.08517 113.657 5.19742 112.573 5.19742Z"
          fill="white"
        />
        <path
          d="M98.5605 16.3155C98.5605 15.416 99.282 14.6868 100.172 14.6868H118.389C119.279 14.6868 120 15.416 120 16.3155C120 17.2151 119.279 17.9442 118.389 17.9442H111.312V22.6182C111.312 23.635 110.497 24.4593 109.49 24.4593C108.484 24.4593 107.669 23.635 107.669 22.6182V17.9442H100.172C99.282 17.9442 98.5605 17.2151 98.5605 16.3155Z"
          fill="white"
        />
        <path
          opacity="0.46"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.7683 13.0351C17.7683 11.9879 16.9192 11.1389 15.872 11.1389H14.6079V9.87474C14.6079 8.82753 13.7588 7.97852 12.7116 7.97852C11.6644 7.97852 10.8154 8.82753 10.8154 9.87474V13.0351C10.8154 13.9889 11.5198 14.7785 12.4368 14.9114C12.5264 14.9243 12.6182 14.9313 12.7116 14.9313H15.872C16.9192 14.9313 17.7683 14.0823 17.7683 13.0351Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.7251 12.9648C7.7251 14.012 8.57411 14.861 9.62131 14.861H10.8855V16.1252C10.8855 17.1724 11.7345 18.0214 12.7817 18.0214C13.8289 18.0214 14.6779 17.1724 14.6779 16.1252V12.9648C14.6779 11.9505 13.8813 11.1222 12.8794 11.0713C12.8712 11.0709 12.8634 11.0706 12.8552 11.0702C12.8309 11.0694 12.8063 11.0686 12.7817 11.0686H9.62131C8.57411 11.0686 7.7251 11.9176 7.7251 12.9648Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9665 0.253174H5.40034C3.02659 0.253174 1.10235 2.17631 1.10235 4.54868V6.11523C0.434318 6.60146 0 7.3894 0 8.27895C0 9.75633 1.19856 10.9542 2.6768 10.9542C4.15503 10.9542 5.35359 9.75633 5.35359 8.27895C5.35359 7.32166 4.85034 6.48156 4.09388 6.00895V4.54868C4.09388 3.82771 4.67856 3.24298 5.40034 3.24298H11.9669C17.3584 3.24298 21.7291 7.6113 21.7291 12.9996C21.7291 18.3879 17.3584 22.7562 11.9669 22.7562H5.40034C4.67894 22.7562 4.09388 22.1717 4.09388 21.4504V19.9901C4.85034 19.5175 5.35359 18.6775 5.35359 17.7203C5.35359 16.2427 4.15503 15.0449 2.6768 15.0449C1.19856 15.0449 0 16.2432 0 17.7211C0 18.6101 0.434318 19.398 1.10235 19.8847V21.4512C1.10235 23.8236 3.02659 25.7467 5.40034 25.7467H11.9669C19.0107 25.7467 24.7211 20.04 24.7211 13C24.7211 5.95989 19.0107 0.253174 11.9665 0.253174Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_166_2361">
        <rect width="120" height="26" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Logo;
