const CaretDown = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.3594 5.3999L8.03125 8.82178L4.70312 5.3999L3.75 6.32178L8.03125 10.728L12.3125 6.32178L11.3594 5.3999Z"
      fill="#8D9297"
    />
  </svg>
);

export default CaretDown;
