import dynamic from 'next/dynamic';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import { Tablet } from '@/styles/layout';

const ReactPlayer = dynamic(() => import('react-player'), { ssr: false });
const MainContentBanner = ({
  videoPlaying,
  onChangePlaying,
}: {
  videoPlaying: boolean;
  onChangePlaying: (playing: boolean) => void;
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width:768px)',
  });

  return (
    <PlayerWrapper>
      {!isMobile && (
        <VideoPlayer
          muted
          playing={videoPlaying}
          url={
            'https://file.dev.doctornow.co.kr/landing/launching_cf_full/master.m3u8'
          }
          onEnded={() => {
            onChangePlaying(false);
          }}
        />
      )}
    </PlayerWrapper>
  );
};

export default MainContentBanner;

const PlayerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  background-color: black;

  @media screen and (max-width: ${Tablet}) {
    height: 780px;
    background-position: center;
    background-size: cover;
    background-color: white;
    background-image: url('https://file.doctornow.co.kr/landing/launching-cf-mobile-main.png');
  }
`;

const VideoPlayer = styled(ReactPlayer)`
  width: 100vw !important;
  height: calc(100vw * 9 / 16) !important;

  @media screen and (max-width: ${Tablet}) {
    display: none !important;
  }
`;
