import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import CaretDown from '@/components/atom/Landing/CaretDown';
import CaretUp from '@/components/atom/Landing/CaretUp';
import { Tablet } from '@/styles/layout';

interface Props {
  opened: boolean;
  title: string;
  onClick: () => void;
}

const FaqItem = ({
  opened,
  title,
  onClick,
  children,
}: PropsWithChildren<Props>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    setHeight(ref.current.clientHeight);
  }, [opened]);
  return (
    <Wrapper>
      <Title opened={opened} onClick={onClick}>
        <TitleText>
          <QMark>Q.</QMark>
          {title}
        </TitleText>
        {opened ? <CaretUp /> : <CaretDown />}
      </Title>
      <ContentWrapper ref={ref} opened={opened} height={height}>
        {children}
      </ContentWrapper>
    </Wrapper>
  );
};

const FaqContentBlock = ({ children }: PropsWithChildren) => {
  return <ContentBlock>{children}</ContentBlock>;
};

FaqItem.ContentBlock = FaqContentBlock;
FaqItem.SmallText = styled.div`
  ${({ theme }) => `
    ${theme.typography.UI14R};
    color: ${theme.color.gray400};
  `}
`;
FaqItem.BoldText = styled.div`
  ${({ theme }) => `
    ${theme.typography.UI16B};
    color: ${theme.color.gray400};
  `}
`;

FaqItem.Href = styled.a`
  text-decoration: underline;
`;

export default FaqItem;

const Wrapper = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;
  width: 690px;
  overflow: hidden;
  border-radius: 8px 8px;

  @media screen and (max-width: ${Tablet}) {
    width: 100%;
  }
`;

const Title = styled.div<{ opened: boolean }>`
  display: flex;
  width: 100%;
  padding: 16px 19px 16px 20px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 3 !important;
  ${({ theme }) => `
    ${theme.typography.UI18SB};
    color: ${theme.color.gray500};
    background: ${theme.color.gray40};
    flex-wrap: nowrap
    
  `}
`;

const TitleText = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

const QMark = styled.div`
  ${({ theme }) => `
    ${theme.typography.UI18SB};
    color: ${theme.color.primary800};
  `}
`;

const ContentWrapper = styled.div<{
  opened: boolean;
  height: number;
}>`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 24px 25px;
  display: flex;

  ${({ opened, height }) =>
    opened
      ? `
      margin-top: 0;
  `
      : `
      margin-top: -${height}px;
      `}
  ${({ theme }) => `
        background: ${theme.color.gray20};
    `};
  transition: margin-top 0.3s linear;
`;

const ContentBlock = styled.div`
  white-space: pre-wrap;
  ${({ theme }) => `
    ${theme.typography.UI16R};
    color: ${theme.color.gray400};
  `}
`;
