import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Tablet } from '@/styles/layout';

const BrandStorySection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [opacity, setOpacity] = useState<number>(0);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const calculateScrollRate = () => {
      if (!ref.current) {
        return;
      }
      const sectionHeight =
        ref.current.clientHeight +
        ref.current.offsetTop -
        window.innerHeight +
        100;
      const scrollY = window.scrollY;

      const scrollRatio = scrollY / sectionHeight;

      const opacity = scrollRatio > 1 ? 1 : scrollRatio;
      setOpacity(opacity);
    };

    window.addEventListener('scroll', calculateScrollRate);
    return () => {
      window.removeEventListener('scroll', calculateScrollRate);
    };
  }, []);
  return (
    <BrandStory ref={ref} opacity={opacity}>
      <Gradient1 />
      <Gradient2 />

      <BrandStoryText data-aos={'fade-up'} data-aos-duration={2000}>
        {
          '병원 문 닫았다는 이유로\n바빠서 어쩔 수 없다는 이유로\n더 이상 아픔을 참거나 미루지 마세요.'
        }
      </BrandStoryText>
      <BrandStoryText data-aos={'fade-up'} data-aos-duration={2000}>
        {'아플 때, 닥터나우를 열면'}
        <BrandStoryHighlightText>
          병원이 우리를 찾아올 거예요.
        </BrandStoryHighlightText>
      </BrandStoryText>
    </BrandStory>
  );
};

export default BrandStorySection;

const Gradient1 = styled.div`
  position: absolute;
  top: -80px;
  width: 100%;
  height: 80px;
  background: linear-gradient(
    180deg,
    rgba(45, 46, 47, 0) 0%,
    rgba(45, 46, 47, 0) 10%,
    #2d2e2f 100%
  );
  overscroll-behavior: contain;

  @media screen and (max-width: ${Tablet}) {
    top: -239px;
    height: 240px;
  }
`;

const Gradient2 = styled.div`
  position: absolute;
  top: -80px;
  width: 100%;
  height: 240px;
  background: linear-gradient(
    180deg,
    rgba(45, 46, 47, 0) 0%,
    rgba(45, 46, 47, 0) 10%,
    #2d2e2f 100%
  );
  overscroll-behavior: contain;
`;
const BrandStory = styled.div<{ opacity: number }>`
  margin-top: calc(100vw * 9 / 16);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 0 200px 0;
  gap: 53px;
  justify-content: center;
  ${({ theme }) => `
    background: ${theme.color.gray800};
  `};

  @media screen and (max-width: ${Tablet}) {
    margin-top: 780px;
    gap: 32px;
  }

  div:nth-child(2) {
    opacity: ${({ opacity }) => opacity.toFixed(1)};
  }
`;

const BrandStoryText = styled.div`
  white-space: pre-wrap;
  text-align: center;
  ${({ theme }) => `
     ${theme.typography.UI32B};
     color: ${theme.color.white}; 
    `};
  @media screen and (max-width: ${Tablet}) {
    ${({ theme }) => `
     ${theme.typography.UI18B};
    `}
  }
`;

const BrandStoryHighlightText = styled(BrandStoryText)`
  ${({ theme }) => `
    color: ${theme.color.primary500};
  `}
`;
