import styled from 'styled-components';

import FaqItem from '@/components/templates/landing/components/FaqItem';
import { Tablet } from '@/styles/layout';

interface Props {
  selectedFaqIndex: number | null;
  clickFaq: (index: number) => void;
}

const FaqSection = ({ selectedFaqIndex, clickFaq }: Props) => {
  return (
    <Section>
      <ContentArea data-aos={'fade-up'}>
        <Title>자주 묻는 질문</Title>
        <FaqList>
          <FaqItem
            onClick={() => clickFaq(0)}
            opened={selectedFaqIndex === 0}
            title={'비대면 진료는 누구나 가능한가요?'}
          >
            <FaqItem.ContentBlock>
              {
                "네, 가능해요! 2024년 2월 23일에 시행된 '비대면 진료 시범사업 지침 개정안 공고'에 따르면, 누구나 365일 24시간 내내 비대면 진료를 이용할 수 있어요.\n\n단, 의사의 판단에 따라 비대면 진료가 어려운 증상의 경우 진료가 취소될 수 있어요."
              }
            </FaqItem.ContentBlock>
            <FaqItem.ContentBlock>
              <FaqItem.SmallText>
                ※ 비대면 진료 시범사업 지침 개정안 공고(보건복지부 공고 제
                2024-239호)
              </FaqItem.SmallText>
            </FaqItem.ContentBlock>
          </FaqItem>
          <FaqItem
            onClick={() => clickFaq(1)}
            opened={selectedFaqIndex === 1}
            title={'비대면 진료는 화상으로만 진행되나요?'}
          >
            <FaqItem.ContentBlock>
              {
                '비대면 진료는 화상진료 또는 음성진료 모두 가능해요.\n원하는 방식을 선택해 비대면 진료 받아보세요.'
              }
            </FaqItem.ContentBlock>
          </FaqItem>
          <FaqItem
            onClick={() => clickFaq(2)}
            opened={selectedFaqIndex === 2}
            title={'비대면 진료 후 처방전은 어떻게 받을 수 있나요?'}
          >
            <FaqItem.ContentBlock>
              비대면 진료가 끝나고 처방전을 받았다면, 해당 처방전을 전송할
              약국을 선택해 달라는 화면이 노출돼요.
            </FaqItem.ContentBlock>
            <FaqItem.ContentBlock>
              <FaqItem.BoldText>[앱으로 접수]</FaqItem.BoldText>
              {
                "'앱으로 접수' 뱃지가 붙은 약국은 닥터나우 제휴 약국으로, 따로 전화할 필요 없이 앱으로 처방전을 보내면 약 조제가 바로 시작되는 약국이에요."
              }
            </FaqItem.ContentBlock>
            <FaqItem.ContentBlock>
              <FaqItem.BoldText>[전화로 접수]</FaqItem.BoldText>
              {
                '닥터나우와 제휴하지 않는 약국에도 처방전을 보내고 약을 픽업해 올 수 있어요. 처방전을 보내기 전에 해당 약국에 전화한 후, 처방약의 조제 가능 여부를 확인하고 처방전을 전송하면 돼요.'
              }
            </FaqItem.ContentBlock>
            <FaqItem.ContentBlock>
              <FaqItem.SmallText>
                {
                  '※ 근처 약국이 보이지 않는 경우 지도의 줌/아웃 기능을 이용해 위치를 변경한 후, \n[이 위치 재검색]을 누르면 더 많은 약국을 조회할 수 있어요.\n※ 닥터나우 앱을 통해 전송되는 처방전은 병원 전산 시스템과 연동된 처방전 원본이에요.'
                }
              </FaqItem.SmallText>
            </FaqItem.ContentBlock>
          </FaqItem>
          <FaqItem
            onClick={() => clickFaq(3)}
            opened={selectedFaqIndex === 3}
            title={'처방전 다운로드 또는 출력이 가능한가요?'}
          >
            <FaqItem.ContentBlock>{`아니요. 현재 보건복지부 시범사업안에 따라, 비대면 진료 후 발급된 처방전은 위·변조 방지를 위해 원본을 다운로드하거나 출력할 수 없어요.\n\n닥터나우 앱을 통해 약국에 처방전을 보내면 복지부 가이드에 맞춰 팩스가 자동 전송돼요. 따라서, 처방전을 따로 출력해 약국에 가져가지 않아도 처방약을 받을 수 있어요.`}</FaqItem.ContentBlock>
          </FaqItem>
          <FaqItem
            onClick={() => clickFaq(4)}
            opened={selectedFaqIndex === 4}
            title={'비대면 진료로 어떤 약이든 처방 받을 수 있나요?'}
          >
            <FaqItem.ContentBlock>
              보건복지부 시범사업안에 따라 마약류, 오·남용 의약품, 사후피임약은
              비대면 진료를 통해 처방받을 수 없어요. 보다 자세한 내용이
              궁금하다면 아래 문서를 확인해 주세요.
            </FaqItem.ContentBlock>
            <FaqItem.ContentBlock>
              <FaqItem.SmallText>
                {
                  '※「마약류 관리에 관한 법률」제 18조 제 2항, 제 21조 제 2항에 따라 마약 및 향정신성\n의약품으로 수입·제조허가를 받은 의약품\n※「오·남용 우려 의약품 지정에 관한 규정」(식약처) 지정 품목(23개 품목(성분) 함유제제)\n※ 사후피임약 : 레보노르게스트렐(0.75mg, 1.5mg), 울리프리스탈(30mg) 함유제제'
                }
              </FaqItem.SmallText>
            </FaqItem.ContentBlock>
            <FaqItem.ContentBlock>
              <FaqItem.Href>비대면진료 처방제한 의약품.pdf</FaqItem.Href>
            </FaqItem.ContentBlock>
          </FaqItem>
        </FaqList>
      </ContentArea>
    </Section>
  );
};
export default FaqSection;

const Section = styled.div`
  width: 100%;
  background: white;
  padding: 120px 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${Tablet}) {
    padding: 80px 10px;
  }
`;

const ContentArea = styled.div`
  display: flex;
  width: 1080px;
  padding: 0;
  flex-direction: column;
  align-items: center;
  gap: 96px;
  flex-shrink: 0;

  @media screen and (max-width: ${Tablet}) {
    width: 100%;
    gap: 40px;
  }
`;

const Title = styled.h3`
  ${({ theme }) => `
        ${theme.typography.UI48B};
        color: ${theme.color.gray900};
        
        @media screen and (max-width: ${Tablet}) {
          ${theme.typography.UI32B};
        }
    `};
`;

const FaqList = styled.div`
  display: flex;
  flex-direction: column;
  width: 690px;
  gap: 16px;

  @media screen and (max-width: ${Tablet}) {
    width: 100%;
  }
`;
