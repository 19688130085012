import { useEffect, useRef, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import Logo from '@/components/atom/Landing/Logo';
import MainContentBanner from '@/components/templates/landing/components/MainContentBanner';
import { DeskTop, Tablet } from '@/styles/layout';

const MainBannerSection = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [opacity, setOpacity] = useState<number>(0);
  const [videoPlaying, setVideoPlaying] = useState<boolean>(true);

  const onChangePlaying = (playing: boolean) => {
    setVideoPlaying(playing);
  };

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const calculate = () => {
      if (!ref.current) {
        return;
      }
      const sectionHeight = ref.current.clientHeight;
      const scrollY = window.scrollY;

      const scrollRatio = scrollY / sectionHeight;

      const opacity = scrollRatio > 1 ? 1 : scrollRatio;
      setOpacity(opacity);
    };

    window.addEventListener('scroll', calculate);
    return () => {
      window.removeEventListener('scroll', calculate);
    };
  }, []);

  return (
    <MainBanner ref={ref} opacity={opacity}>
      <MainContentBanner
        onChangePlaying={onChangePlaying}
        videoPlaying={videoPlaying}
      />
      {!videoPlaying && <ThumbnailOverlay opacity={opacity} />}
      <ContentWrapper>
        <LogoHeader>
          <Logo />
        </LogoHeader>
        {!videoPlaying && (
          <Content>
            <MainCopyContainer>
              <MainCopyrightImg
                src={'https://file.doctornow.co.kr/landing/catchphrase.png'}
              />
            </MainCopyContainer>
            <DownloadButton
              onClick={() => window.open('https://abr.ge/0os6ry')}
            >
              앱 다운로드
            </DownloadButton>
            <PlayButtonIcon onClick={() => onChangePlaying(true)} />
            <FloatingArrowDown />
          </Content>
        )}
      </ContentWrapper>
    </MainBanner>
  );
};

export default MainBannerSection;

const BottomUp = keyframes`
    0% {
        transform: translate(-50%, 20px);
    }
    100% {
        transform: translate(-50%, -20px);
    }
`;

const MainBanner = styled.div<{ opacity: number }>`
  position: fixed;
  width: 100vw;
  height: calc(100vw * 9 / 16) !important;

  background-size: cover;
  background-position: center;
  background-color: black;

  display: flex;
  flex-direction: column;
  align-items: center;
  isolation: isolate;

  @media screen and (max-width: ${Tablet}) {
    width: 100%;
    height: 720px !important;
    background-color: white;
  }

  &::after {
    content: '';
    position: absolute;
    background: #2d2e2f;
    z-index: 1;
    inset: 0;
    opacity: ${({ opacity }) => opacity * 1.1};
  }

  ${({ theme }) =>
    !theme.client.isMobile &&
    css`
      &::before {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        z-index: -1;

        content: url('https://file.doctornow.co.kr/landing/catchphrase.png')
          url('https://file.doctornow.co.kr/landing/cf-web-main-thumbnail.jpg');
      }
    `}
`;

const ContentWrapper = styled.div`
  position: absolute;
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
`;

const LogoHeader = styled.div`
  padding: 16px 0;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media screen and (max-width: ${Tablet}) {
    height: 60px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc((100vw * 9 / 16) / 35);

  @media screen and (max-width: ${Tablet}) {
    display: none;
  }
`;

const MainCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: calc((100vw * 9 / 16) / 5.5);

  @media screen and (max-width: ${DeskTop}) {
    margin-top: calc((100vw * 9 / 16) / 20);
  }
`;

const MainCopyrightImg = styled.img`
  width: 28vw;
  object-fit: contain;
  transform: translateX(-11%);
`;

const DownloadButton = styled.button`
  width: 200px;
  height: 52px;
  border-radius: 8px;
  padding: 0 12px;

  ${({ theme }) => `
    background: rgba(255, 255, 255, 0.20);
    border: 1px solid ${theme.color.gray100};
    color: ${theme.color.white};
    ${theme.typography.UI17SB};
  `};
  @media screen and (max-width: ${Tablet}) {
    display: none;
  }
`;

const ThumbnailOverlay = styled.div<{ opacity: number }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-position: center;
  background-image: url('https://file.doctornow.co.kr/landing/cf-web-main-thumbnail.jpg');

  &::after {
    content: '';
    position: absolute;
    background: #2d2e2f;
    z-index: -1;
    inset: 0;
    opacity: ${({ opacity }) => opacity * 1.1};
  }

  @media screen and (max-width: ${Tablet}) {
    display: none;
  }
`;

const PlayButtonIcon = styled.div`
  position: absolute;
  width: 6vw;
  min-width: 100px;
  aspect-ratio: 1;
  background-image: url('https://file.doctornow.co.kr/landing/play-circle.png');
  opacity: 0.7;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const FloatingArrowDown = styled.div`
  position: absolute;
  bottom: 2.5%;
  left: 50%;
  width: 80px;
  height: 80px;
  background-image: url('https://file.doctornow.co.kr/landing/floating_arrow_down.png');
  opacity: 0.6;
  background-size: cover;
  background-position: center;
  animation: ${BottomUp} 1s infinite alternate;
`;
