import { useState } from 'react';

export interface MockReview {
  tag: string;
  icon: string;
  title: string;
  content: string;
  author: string;
}
const nestedReviews: MockReview[][] = [
  [
    {
      tag: '감기/몸살',
      icon: 'https://file.doctornow.co.kr/landing/tag-cold.png',
      title: '아파서 움직일 수 없을 때 한 줄기 빛!',
      content:
        '움직일 수조차 없이 아파서 선택지가 없었는데 처방해주신 약 먹고 한숨 자고 일어났더니 안정을 찾았어요. 참고로 약도 엄마가 찾아와 주셨어요! 이런 앱도 있다는 것을 널리 알리고 싶어요!',
      author: '이00 님',
    },
    {
      tag: '근육통',
      icon: 'https://file.doctornow.co.kr/landing/tag-pain.png',
      title: '이제 멀어서 병원 못 갈 걱정은 없어요.',
      content:
        '살면서 봬온 선생님 중 가장 친절하셨습니다. 이해하기 쉽도록 정말 자세히 설명해주셨고 이렇게 좋으신 선생님께 진료받아서 감사했습니다. 병원 가까이 거주하시는 분들이 정말 부럽네요 :)',
      author: '이00 님',
    },
    {
      tag: '탈모',
      icon: 'https://file.doctornow.co.kr/landing/tag-comb.png',
      title: '병원가기 어려운 직장인에게 최고의 어플!',
      content:
        '다이어트 성공한 지인 추천으로 진료 받아봤습니다. 약은 처음인데 원장님께서 친절하게 잘 설명해주셨어요. 식단과 약을 같이 병행할 예정인데 한달 뒤에 성공 후기 남길 수 있으면 좋겠습니다.',
      author: '변00 님',
    },
    {
      tag: '다이어트',
      icon: 'https://file.doctornow.co.kr/landing/tag-diet.png',
      title: '지인 추천으로 다이어트 진료 받았어요.',
      content:
        '다이어트 성공한 지인 추천으로 진료 받아봤습니다. 약은 처음인데 원장님께서 친절하게 잘 설명해주셨어요. 식단과 약을 같이 병행할 예정인데 한달 뒤에 성공 후기 남길 수 있으면 좋겠습니다.',
      author: '김00 님',
    },
    {
      tag: '다래끼/눈 충혈',
      icon: 'https://file.doctornow.co.kr/landing/tag-eye.png',
      title: '비 오는 날 외출 안하고 바로 진료 가능!',
      content:
        '비가 너무 많이 와서 닥터나우로 진료받았어요. 별생각 없이 리뷰 좋아서 병원 선택했는데 진짜 선생님 너무너무 좋습니다! 진심으로 추천합니다! 상태도 꼼꼼하게 잘 물어봐 주시고 비대면 아닌 줄 알았어요.',
      author: '지00 님',
    },
    {
      tag: '여성질환',
      icon: 'https://file.doctornow.co.kr/landing/tag-woman.png',
      title: '병원가기 민망했는데 비대면 진료로 해결!',
      content:
        '자세히 설명해주셔서 대면 진료받은 느낌이에요. 질염이 정말 지긋지긋한데 병원까지 가기 망설여지더라고요. 산부인과 가면 오래 대기해야 하기도 하고요. 자세하게 물어봐 주셔서 신뢰도가 급상승했어요.',
      author: '지00 님',
    },
  ],
  [
    {
      tag: '통증',
      icon: 'https://file.doctornow.co.kr/landing/tag-pain.png',
      title: '가족 진료도 섬세하게 잘해주셨어요!',
      content:
        '지방 소도시라서 병원이 얼마 없는 곳인데 닥터나우 덕분에 밤늦은 시간에도 대기 없이 편하게 진료를 볼 수 있어 매우 좋아요! 진료도 세세하게 잘 봐주시고 늦은 시간이지만 친절하게 진료 봐주셔서 감사드립니다.',
      author: '전00 님',
    },
    {
      tag: '감기/몸살',
      icon: 'https://file.doctornow.co.kr/landing/tag-cold.png',
      title: '병원이 적은 지역이라면 꼭 필요한 어플!',
      content:
        '지방 소도시라서 병원이 얼마 없는 곳인데 닥터나우 덕분에 밤늦은 시간에도 대기 없이 편하게 진료를 볼 수 있어 매우 좋아요! 진료도 세세하게 잘 봐주시고 늦은 시간이지만 친절하게 진료 봐주셔서 감사드립니다.',
      author: '김00 님',
    },
    {
      tag: '소아과',
      icon: 'https://file.doctornow.co.kr/landing/tag-baby.png',
      title: '아이들은 왜 이렇게 연휴에 많이 아플까요?',
      content:
        '연휴 기간도 교대 근무하는 맞벌이 부부라 애 둘을 데리고 소아과 대기하는 게 쉬운 일이 아니에요. 가벼운 감기라 약을 먹여야 할 것 같아서 비대면진료 처음 받아봤어요. 퇴근 중인 애들 아빠에게 약국에서 약 받아오라 하니 너무 편하네요!',
      author: '김00 님',
    },
    {
      tag: '다이어트',
      icon: 'https://file.doctornow.co.kr/landing/tag-diet.png',
      title: '휴일에도 걱정없이 진료볼 수 있어요!',
      content:
        '휴일에 모든 병원이 쉴 때 닥터나우에서 이비인후과 운영하고 있으면서 별점도 높은 선생님 진료를 봤는데 왜 별점이 높은지 알 것 같습니다. 매우 친절하시고 세밀하게 물어봐 주셔서 정확한 처방을 받을 수 있었습니다. 감사합니다!',
      author: '김00 님',
    },
    {
      tag: '비염',
      icon: 'https://file.doctornow.co.kr/landing/tag-nose.png',
      title: '비염으로 힘들었는데 숨쉬기가 편해졌어요.',
      content:
        '비염으로 늘 고생 중인데 약은 면역이 생겨버려서 걱정이었는데요. 선생님께서 처방해주신 약으로 3~4일만에 확실히 좋아져서 숨쉬기가 편해졌어요!! 친절하게 설명도 해주셔서 감사합니다. 비대면 진료는 처음인데 자주 애용할 것 같아요.',
      author: '지00 님',
    },
    {
      tag: '공황장애',
      icon: 'https://file.doctornow.co.kr/landing/tag-mind.png',
      title: '새 삶을 얻은 기분이예요.',
      content:
        '공황장애를 앓은 지 10년이 넘어서 평생 이렇게 살아야만 하는 줄 알았는데 원장님의 미션을 잘 이행하니 전보다 많이 좋아졌네요. 사람들과의 만남도 전보다는 많이 좋아졌습니다. 새 삶을 얻은 기분이네요. 하루하루 감사히 도전하면서 살아 보렵니다.',
      author: '심00 님',
    },
  ],
];

interface LandingViewModel {
  nestedReviews: MockReview[][];
  openedFaqIndex: number | null;
  openFaq: (index: number) => void;
}

const useLandingViewModel = (): LandingViewModel => {
  const [openedFaqIndex, setOpenedFaqIndex] = useState<number | null>(null);

  const openFaq = (index: number) => {
    if (index === openedFaqIndex) {
      setOpenedFaqIndex(null);
      return;
    }

    setOpenedFaqIndex(index);
  };

  return {
    nestedReviews: nestedReviews,
    openedFaqIndex: openedFaqIndex,
    openFaq: openFaq,
  };
};

export default useLandingViewModel;
