const StarIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M10.7038 1.72538C11.2824 0.731982 12.7176 0.731982 13.2962 1.72538L16.0316 6.42187C16.2436 6.78577 16.5987 7.04382 17.0103 7.13294L22.3223 8.28322C23.4458 8.52653 23.8893 9.89142 23.1233 10.7487L19.502 14.8015C19.2214 15.1156 19.0858 15.5331 19.1282 15.9521L19.6757 21.3595C19.7915 22.5033 18.6304 23.3468 17.5784 22.8832L12.6049 20.6915C12.2195 20.5217 11.7805 20.5217 11.3951 20.6915L6.42157 22.8832C5.36956 23.3468 4.20852 22.5033 4.32432 21.3595L4.87182 15.9521C4.91424 15.5331 4.77858 15.1156 4.49799 14.8015L0.876664 10.7487C0.110682 9.89142 0.554161 8.52653 1.67774 8.28322L6.98967 7.13294C7.40126 7.04382 7.75642 6.78577 7.96838 6.42187L10.7038 1.72538Z"
      fill="#FF8D00"
    />
  </svg>
);

export default StarIcon;
