const CaretUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M11.625 10.6997L8.28125 7.27783L4.95312 10.6997L4 9.76221L8.28125 5.35596L12.5781 9.76221L11.625 10.6997Z"
      fill="#8D9297"
    />
  </svg>
);

export default CaretUp;
