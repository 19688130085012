import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { Client } from '@/types/app';
import { getPageName, logger } from '@/utils/logger';

interface Props {
  label: string;
  client: Client;
}

const ExposeLogger = ({ label, client }: Props) => {
  const { inView, ref } = useInView({ triggerOnce: true });
  const router = useRouter();

  useEffect(() => {
    if (inView) {
      logger({
        pageName: getPageName(router),
        eventName: `user_web_home_${label}_section_exposed`,
        payload: {
          isMobile: String(client.isMobile),
        },
      });
    }
  }, [inView, router]);

  return <Wrapper ref={ref} />;
};

export default ExposeLogger;

const Wrapper = styled.div``;
