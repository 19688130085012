import dynamic from 'next/dynamic';
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import DownloadFooter from '@/components/templates/landing/components/DownloadFooter';
import MobileAppDownloadFooter from '@/components/templates/landing/components/MobileAppDownloadFooter';
import { Client } from '@/types/app';
import { isMobileWebByUserAgent } from '@/utils/mobile/RNPlugins';

const Footer = dynamic(() => import('@/components/layouts/Footer'));

interface Props {
  children: React.ReactNode;
  userAgent: string;
  client: Client;
  customGlobalSideBar?: React.ReactNode;
}

const CustomGlobal = createGlobalStyle`
    html, body {
        overscroll-behavior: none;
        min-width: 360px;
    }
`;

const LandingLayout = ({ children, userAgent }: Props) => {
  const isMobileWebView = isMobileWebByUserAgent(userAgent);

  return (
    <Wrapper>
      <CustomGlobal />
      {children}
      <Footer />
      {isMobileWebView ? <MobileAppDownloadFooter /> : <DownloadFooter />}
    </Wrapper>
  );
};

export default LandingLayout;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  footer {
    z-index: 2;
    position: relative;
  }

  overscroll-behavior-x: none;
`;
